/* RESET - https://github.com/digitalinteraction/alembic/blob/main/src/lib/reset.css */

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* STYLES */

body {
  color: white;
  background-color: black;
}
aside {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 420px;
  padding: 0.5em;
}
aside p {
  background-color: white;
  color: black;
  padding: 1em;
  border-radius: 1em;
  margin: 0 0 1em;
  opacity: 0.7;
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}
#localVideo {
  position: fixed;
  right: 1em;
  bottom: 1em;
  width: 20vw;
  width: 25vw;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  outline: 5px solid royalblue;
  z-index: 1;
  border-radius: 0.5em;
}
h1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 1rem;
  text-align: center;
  font-weight: 400;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
#version {
  position: fixed;
  left: 0.25rem;
  bottom: 0.25rem;
  font-size: 10px;
  text-shadow: 1px 1px 5px black;
}
[aria-hidden='true'] {
  display: none;
}
main {
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: repeat(var(--columns, 1), 1fr);
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
  gap: 0.5em;
}
main > * {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
main > .debug {
  background-color: coral;
  border: 2px solid white;
}

.notifications {
  position: fixed;
  top: 1em;
  right: 1em;
  width: 100%;
  max-width: 420px;

  display: flex;
  flex-direction: column;
  gap: 0.75em;
}
.notifications > * {
  background-color: royalblue;
  color: white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin: 0;
  opacity: 0.8;
}
.notifications > [data-type='error'] {
  background-color: #e24335;
}
